import { useQuery, gql } from "@apollo/client";

const SHOP_MENU_QUERY = gql`
  query ShopMenu($locale: String!) {
    shopMenu(locale: $locale)
  }
`;

const useShopMenu = ({ locale = "de" } = {}) => {
  const { data, loading, error } = useQuery(SHOP_MENU_QUERY, {
    variables: { locale },
  });

  return {
    menu: data?.shopMenu || [],
    loading,
    error,
  };
};

export const preMainShopMenu = async (apolloClient, { locale }) => {
  const { data } = await apolloClient.query({
    query: SHOP_MENU_QUERY,
    variables: { locale },
  });
  return data;
};
export default useShopMenu;
