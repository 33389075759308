import { useLazyQuery, gql } from "@apollo/client";

const ProductStockQuery = gql`
  query ProductStock($productId: ID) {
    product(productId: $productId) {
      _id
      status
      ... on SimpleProduct {
        simulatedStocks {
          quantity
        }
        simulatedDispatches {
          earliestDelivery
        }
      }
    }
  }
`;

const useProductStock = ({ productId }) => {
  const [loadPrice, { called, data = {}, loading, error }] = useLazyQuery(
    ProductStockQuery,
    {
      ssr: false,
      variables: {
        productId,
      },
    },
  );

  return {
    loadPrice,
    called,
    loading,
    error,
    product: data?.product,
  };
};

export default useProductStock;
